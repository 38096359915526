import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select'

// Connects to data-controller="select"
export default class extends Controller {
  static targets = ['select', 'form', 'textInput']
  static values = { url: String }

  connect() {
    super.connect()
    this.initTomSelect()
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  initTomSelect() {
    if (this.hasSelectTarget) {
      let url = `${this.urlValue}`

      this.select = new TomSelect(this.selectTarget, {
        plugins: ['input_autogrow', 'remove_button', 'restore_on_backspace'],
        valueField: 'id',
        labelField: 'displayName',
        searchField: ['displayName', 'description'],
        maxItems: 1,
        selectOnTab: true,
        placeholder: 'Type and select to initiate a command',
        closeAfterSelect: true,
        hidePlaceholder: false,
        preload: false,
        create: false,
        openOnFocus: false,
        highlight: true,
        maxOptions: 10,
        allowEmptyOption: true,
        persist: true,
        sortField: {
          field: 'name',
          direction: 'asc',
        },
        onDelete: (values) => {
          console.log('Deleted:', values)
        },
        onItemAdd: (value, item) => {
          // After an item is selected, focus on the input
          this.select.control_input.focus()
          // Clear the search to allow for additional typing
          this.select.setTextboxValue('')
        },
        onBlur: () => {
          this.textInputTarget.value = this.select.lastQuery
        },
        onType: (str) => {
          console.log('Typed text:', str)
          this.textInputTarget.value = str
        },
        onChange: (value) => {
          console.log(value)
        },
        load: (search, callback) => {
          fetch(`${url}?query=${search}`)
            .then((response) => response.json())
            .then((json) => {
              console.log('Load:', json)
              callback(json)
            })
            .catch(() => {
              callback()
            })
        },
        render: {
          option: function (data, escape) {
            return (
              '<div>' +
              '<span class="block text-white">' +
              escape(data.display_name) +
              '</span>' +
              '<span class="text-gray-400">' +
              escape(data.description) +
              '</span>' +
              '</div>'
            )
          },
          item: function (data, escape) {
            return (
              '<div class="text-gray-400 flex items-center h-full">' +
              '<span>' +
              escape(data.display_name) +
              '</span>' +
              '</div>'
            )
          },
          no_results: null,
        },
      })

      this.formTarget.addEventListener('submit', (event) => {
        event.preventDefault()
        const selectedOption = this.select.getValue()
        const additionalText = this.select.control_input.value
        console.log('Selected option:', selectedOption)

        if (additionalText && additionalText !== '') {
          this.textInputTarget.value = additionalText
        }

        console.log('Additional text:', this.textInputTarget.value)
        this.formTarget.submit()
        this.select.clear()
        this.select.clearOptions()
        this.select.control_input.value = ""
      })
    }
  }
}
